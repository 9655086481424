<template>
  <div class="terms-frame">
    <h1 class="title">개인정보취급방침</h1>
    <div class="line-divide-thick"></div>
    <p v-html="getContent(text)" class="text"></p>
    <div class="line-divide-thin"></div>
  </div>
</template>

<script>
import terms_text from "@/assets/data/terms_text";

export default {
  name: "Private",
  data() {
    return {
      text: terms_text.private
    }
  },
  methods: {
    getContent(content) {
      return content.split('\n').join('<br>');
    },
  },
}
</script>

<style scoped>
.terms-frame {
  padding: 39px 153px 73px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-bottom: 30px;
}

.line-divide-thick {
  height: 2px;
  background: #000000;
  width: 100%;
}

.line-divide-thin {
  height: 1px;
  background: #000000;
  width: 100%;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #999999;
  margin: 20px 0;
  padding: 20px;
  word-break: keep-all;
}


/deep/ .sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #000000;
  margin: 0;
}
</style>
